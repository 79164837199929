<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header">
                        <slot name="header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </slot>
                    </div>
                    <div class="modal-body">
                        <slot name="body"></slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('close')">Close</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
    .modal-mask{position: fixed; z-index: 9999; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0, 0.5); display: table; transition: opacity 0.3 ease;
        .modal-wrapper{display: table-cell; vertical-align: middle; 
            .modal-container{width: 700px; margin: 0 auto; padding: 20px 30px; background-color: #fff; border-radius: 2px; transition: opacity 0.3 ease;}
                .modal-header h3{margin-top: 0; color: black; font: normal normal normal 33px Noto Sans KR;}
                .modal-body{margin: 20px 0; font: normal normal bold 23px/30px Noto Sans KR;
                    p{margin-bottom: 0;}
                }
        }
    }
</style>