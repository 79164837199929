<template>
  <div class="Service">
    <div class="inner">
      <div class="sub_visual sub1bg">
        <div class="in">
          <!-- <p class="su_tit">고객센터</p> -->
        </div>
      </div>
    </div>
    <div class="sub_content">
      <div class="main">
        <input
          type="radio"
          id="tab-1"
          @click="changeType('공지사항')"
          name="show"
          checked
        />
        <input
          type="radio"
          id="tab-2"
          @click="changeType('자료실')"
          name="show"
        />
        <input type="radio" id="tab-3" @click="changeType('FAQ')" name="show" />
        <input
          type="radio"
          id="tab-4"
          @click="changeType('관심고객등록')"
          name="show"
        />
        <div class="tab">
          <label for="tab-1">공지사항</label>
          <label for="tab-2">자료실</label>
          <label for="tab-3">FAQ</label>
          <label for="tab-4">관심고객등록</label>
        </div>
        <div class="content">
          <!-- 공지사항 -->
          <div class="content-dis">
            <div class="titlebox">
              <div class="title">
                <h2>공지사항</h2>
              </div>
            </div>
            <div class="boardList">
              <table style="border-bottom: 1px solid #d5d5d5">
                <thead>
                  <tr>
                    <th class="no">번호</th>
                    <th class="ti">제목</th>
                    <th class="da">등록일</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="no">3</td>
                    <td>
                      <a
                        href="/file/notification/구산주택 입주안내문.pdf"
                        download
                        >구산주택 입주안내문
                      </a>
                    </td>
                    <td class="no">2022-05-11</td>
                  </tr>

                  <tr>
                    <td class="no">2</td>
                    <td>
                      <a
                        href="/file/notification/구산주택 계약안내문.pdf"
                        download
                        >구산주택 계약안내문
                      </a>
                    </td>
                    <td class="no">2022-03-25</td>
                  </tr>

                  <tr>
                    <td class="no">1</td>
                    <td>
                      <a
                        href="/file/notification/구산주택 모집공고문.pdf"
                        download
                        >구산주택 모집 공고문</a
                      >
                    </td>
                    <td class="no">2022-03-10</td>
                  </tr>
                  <!-- <tr>
                                        <td class="no">10</td>
                                        <td @click="ShowNotice('공지1', '공지 내용111111')">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">9</td>
                                        <td @click="ShowNotice('공지2', '공지\n내용\n1234')">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr class="last">
                                        <td class="no">1</td>
                                        <td @click="ShowNotice()">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</td>
                                        <td class="no">2022-02-22</td>
                                    </tr> -->
                </tbody>
              </table>
            </div>
          </div>
          <!-- 자료실 -->
          <div class="content-dis">
            <div class="titlebox">
              <div class="title">
                <h2>자료실</h2>
              </div>
            </div>
            <div class="boardList">
              <table>
                <thead>
                  <tr>
                    <th class="no">번호</th>
                    <th class="ti">제목</th>
                    <th class="da">등록일</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <tr v-for="DataList in DataLists" :key="DataList.title"> 
                                        <td class="no">{{DataList.no}}</td>
                                        <td><a v-bind:href="link" download>{{DataList.title}} </a></td>
                                        <td class="no">{{DataList.date}}</td>
                                    </tr> -->
                  <tr>
                    <td class="no">15</td>
                    <td>
                      <a href="/file/notification/건축물대장.pdf" download
                        >건축물대장
                      </a>
                    </td>
                    <td class="no">2022-06-07</td>
                  </tr>
                  <tr>
                    <td class="no">14</td>
                    <td>
                      <a
                        href="/file/notification/(주)덕영 사업자등록증.pdf"
                        download
                        >(주)덕영 사업자등록증
                      </a>
                    </td>
                    <td class="no">2022-05-11</td>
                  </tr>

                  <tr>
                    <td class="no">13</td>
                    <td>
                      <a
                        href="/file/notification/사용승인필증(구산동 198-3외 2필지).pdf"
                        download
                        >사용승인필증(구산동 198-3외 2필지)
                      </a>
                    </td>
                    <td class="no">2022-05-11</td>
                  </tr>
                  <tr>
                    <td class="no">12</td>
                    <td>
                      <a href="/file/notification/구산주택 계약서.pdf" download
                        >구산주택 계약서
                      </a>
                    </td>
                    <td class="no">2022-03-25</td>
                  </tr>
                  <tr>
                    <td class="no">11</td>
                    <td><a href="/file/data/위임장.pdf" download>위임장</a></td>
                    <td class="no">2022-04-18</td>
                  </tr>
                  <tr>
                    <td class="no">10</td>
                    <td>
                      <a href="/file/data/동호수배치.pdf" download
                        >동호수배치</a
                      >
                    </td>
                    <td class="no">2022-03-18</td>
                  </tr>
                  <tr>
                    <td class="no">9</td>
                    <td>
                      <a
                        href="/file/data/구산주택 세대별 세부정보 및 안목치수.pdf"
                        download
                        >구산주택 세대별 세부정보 및 안목치수</a
                      >
                    </td>
                    <td class="no">2022-03-10</td>
                  </tr>
                  <tr>
                    <td class="no">8</td>
                    <td>
                      <a
                        href="/file/data/개인정보 수집 이용 및 제3자 제공 동의서(임차인).pdf"
                        download
                        >개인정보 수집 이용 및 제3자 제공 동의서(임차인)</a
                      >
                    </td>
                    <td class="no">2022-03-10</td>
                  </tr>
                  <tr>
                    <td class="no">7</td>
                    <td>
                      <a href="/file/data/의무준수 확약서(임차인).pdf" download
                        >의무준수 확약서(임차인)</a
                      >
                    </td>
                    <td class="no">2022-03-10</td>
                  </tr>
                  <tr>
                    <td class="no">6</td>
                    <td>
                      <a
                        href="/file/data/예비신혼부부 신청 확인서(예비신혼부부 세대구성 확인서).pdf"
                        download
                        >예비신혼부부 신청 확인서(예비신혼부부 세대구성
                        확인서)</a
                      >
                    </td>
                    <td class="no">2022-03-10</td>
                  </tr>
                  <tr>
                    <td class="no">5</td>
                    <td>
                      <a
                        href="/file/data/공공지원민간임대주택 등 공급신청서.pdf"
                        download
                        >공공지원민간임대주택 등 공급신청서</a
                      >
                    </td>
                    <td class="no">2022-03-10</td>
                  </tr>
                  <tr>
                    <td class="no">4</td>
                    <td>
                      <a href="/file/data/비사업자 확인각서.pdf" download
                        >비사업자 확인각서</a
                      >
                    </td>
                    <td class="no">2022-03-10</td>
                  </tr>
                  <tr>
                    <td class="no">3</td>
                    <td>
                      <a href="/file/data/월평균 소득현황.pdf" download
                        >월평균 소득현황</a
                      >
                    </td>
                    <td class="no">2022-03-10</td>
                  </tr>
                  <tr>
                    <td class="no">2</td>
                    <td><a href="/file/data/서약서.pdf" download>서약서</a></td>
                    <td class="no">2022-03-10</td>
                  </tr>
                  <tr class="last">
                    <td class="no">1</td>
                    <td>
                      <a href="/file/data/자산보유사실확인서.pdf" download
                        >자산보유사실확인서</a
                      >
                    </td>
                    <td class="no">2022-03-10</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <b-pagination :total-rows="totalRows" v-model="currentPage" :per-page="perPage" style="position:relative; margin-top: 15px; justify-content:center; padding: 0;"></b-pagination> -->
          </div>
          <!-- FAQ -->
          <div class="content-dis">
            <div class="titlebox">
              <div class="title">
                <h2>FAQ</h2>
              </div>
              <div class="F_list">
                <div
                  class="faq-content"
                  v-for="(list, index) in lists"
                  :key="list.title"
                >
                  <button
                    class="question"
                    :id="'que-' + index"
                    @click="openAnswer(index)"
                  >
                    <span class="faq_tit" style="padding-right: 34px">Q</span
                    >{{ list.title }}<span></span
                    ><img
                      src="@/assets/icon/free-icon-down-arrow-54785.png"
                      class="down"
                    />
                  </button>
                  <div
                    class="answer"
                    :id="'ans-' + index"
                    style="white-space: pre-line"
                  >
                    <td>
                      <span
                        class="faq_tit"
                        style="color: #999; padding-left: 45px"
                        >A</span
                      >
                    </td>
                    <td class="answers">
                      {{ list.content }}
                      <img :src="list.img" />
                    </td>
                  </div>
                </div>
              </div>
              <b-pagination
                :total-rows="totalRows"
                v-model="currentPage"
                :per-page="perPage"
                style="
                  position: relative;
                  margin-top: 15px;
                  justify-content: center;
                  padding: 0;
                "
              ></b-pagination>
            </div>
          </div>
          <!-- 관심고객등록 -->
          <div class="content-dis">
            <div class="titlebox">
              <div class="title">
                <h2>관심고객등록</h2>
              </div>
            </div>
            <div class="interest">
              <table>
                <tbody>
                  <tr style="border-top: 2px solid #707070">
                    <td class="content_title">성명</td>
                    <td>
                      <input
                        type="text"
                        placeholder="이름"
                        name="user_name"
                        id="user_Name"
                        v-model="userData.user_name"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="content_title">전화번호</td>
                    <td>
                      <input
                        type="tel"
                        size="30"
                        id="user_Number"
                        max="11"
                        placeholder=""
                        v-model="userData.user_number"
                      />
                      '-'을 제외한 휴대폰번호를 입력해 주세요
                    </td>
                  </tr>
                  <tr>
                    <td class="content_title">생년월일</td>
                    <td>
                      <input
                        type="text"
                        class="non-kr"
                        id="user_Birth"
                        placeholder=""
                        aria-invalid="false"
                        v-model="userData.user_birth"
                      />
                      예) 2019-12-31
                    </td>
                  </tr>
                  <tr style="border-bottom: 2px solid #707070">
                    <td class="content_title">이메일</td>
                    <td>
                      <input
                        type="text"
                        id="user_email"
                        placeholder="이메일"
                        v-model="userData.user_email"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <button type="button" @click="favoriteBtn(userData)">
                <p>조회하기</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="isModalNotice" @close="isModalNotice = false">
      <h3 slot="header">
        {{ noticeTitle }}
        <i
          class="fas fa-times closeModalBtn"
          @click="isModalNotice = false"
        ></i>
      </h3>
      <div slot="body" v-html="replaceNewLine" style="word-break: break-all">
        {{ noticeContent }}
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios';
import Modal from '../components/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    contract_type: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  watch: {
    contract_type: {
      handler(type) {
        this.changeView(type);
      },
      deep: true,
    },
    list() {
      this.count = this.cnt;
    },
  },
  computed: {
    replaceNewLine() {
      return this.noticeContent.replace(/\n/gi, '<br />');
    },
    // rows() {
    //     return this.listFaq.length
    // }
    lists() {
      const list = this.FAQList;

      return list.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },

    totalRows() {
      return this.FAQList.length;
    },
  },
  // replaceContent(){
  //     const returnArray = this.FAQList.map(data=>{return {...data, content: data.content.replace("\n", "<br />") }} )
  //     return returnArray
  // },
  // replaceContent(){
  //     return this.FAQList.replace("\n", "<br />")
  // },
  data() {
    return {
      userData: {
        user_name: '', // 이름
        user_birth: '', // 생년월일
        user_number: '', // 핸드폰 번호
        user_email: '', // 이메일
      },
      show: true,
      isModalNotice: false,
      noticeTitle: '',
      noticeContent: '',
      selected: 'selected.item',
      FAQList: [
        {
          title: '청약 신청 시 제출서류는 어떤 것이 잇나요?',
          content:
            '청약시 서류는 필요없습니다. \n 모든 제출서류는 청약 ‘당첨자’만 제출하게 됩니다.',
        },
        {
          title: '공공임대에는 지원 할 수 없나요?',
          content:
            '불가능합니다. \n 당사는 민간임대주택이며, 공공임대는 SH서울주택도시공사에서 지원이 가능합니다.',
        },
        {
          title: '특별공급이랑 일반공급의 차이점이 무엇인가요?',
          content:
            '자격 조건이 다릅니다. \n - 일반공급 지원 자격조건 \n ① 만19세~39세 이하의 청년 ② 무주택자 ③ 미혼(신혼부부의 경우 기혼) ④ 차량 미소유 및 미운행자 \n - 특별공급 지원 자격조건 \n ① 일반공급 지원자격조건 ② 1인가구 월평균소득 3,854,536원 이하(세전) ③ 본인자산가액 2억 8,800만원 이하 \n 자세한 사항은 모집공고문을 참고해주시기 바랍니다.',
        },
        {
          title: '특별, 일반공급에 중복 신청 할 수 있나요?',
          content:
            '불가능합니다. \n 1세대 1주택만 신청가능하며, 중복 신청 할 경우 전부 무효 처리됩니다.',
        },
        {
          title: '선호층수대로 배정이 되나요?',
          content:
            '청약신청 시 선호층수는 최대한 배정을 해드리고자하나, 본인이 선호하는 층수에 반드시 배정되지 않을 수 있고, 당첨확률과는 전혀 무관합니다. ',
        },
        {
          title: '입주일 외에는 입주 할 수 없나요?',
          content:
            '입주기간(예정)은 2022. 5. 26 ~ 5. 31이며, 이후에도 입주 할 수 있습니다. \n 다만, 모든 입주자는 2022. 6. 1.부터 월임료가 발생합니다. \n 자세한 사항은 모집공고문을 참고해주시기 바랍니다.',
        },
        {
          title: '입주일/선호층수가 당첨확률과 관계있나요?',
          content: '관계 없습니다.',
        },
        {
          title: '모델하우스가 있나요?',
          content:
            '없습니다. \n 당사 홈페이지(https://duckyoung2016.com) 타입별 VR을 참고해주시기 바랍니다.',
        },
        {
          title: '모바일로 홈페이지 접속이 안돼요. 모바일은 안되나요?',
          content:
            '안됩니다. \n 인터넷청약은 PC만 접속 및 신청이 가능하오니 양해 바랍니다.',
        },

        {
          title: '당첨자 발표 후 예비당첨자도 발표하나요?',
          content:
            '하지 않습니다. \n 청약 당첨자의 계약 및 당첨 취소 시 개별 연락 드립니다.',
        },
        {
          title: '계약기간은 어떻게 되나요?',
          content:
            '최초 계약기간은 2년입니다. \n 최대 8년까지 갱신 가능하며 갱신계약시 입주조건은 모집공고문을 참고해주시기 바랍니다.',
        },
        {
          title: '특별공급 신청란에 월평균소득과 자산은 어떻게 적나요?',
          content:
            '모집공고문 내 ‘특별공급 제출서류’에 소득여부 및 자산자료를 참고하셔서 작성하시면 됩니다. \n 추후 원본은 제출하오니 ‘부적격사유’에 대한 소명의무는 전적으로 공급신청자에게 있습니다.',
        },
        {
          title: '보증금 및 임대료를 조정 할 수 있나요?',
          content:
            '모집공고문에 게시되어 있는 임대보증금비율 30%, 35%, 40% 이외에는 조정 및 변경 불가입니다.',
        },
        {
          title: '자동차가 있으면 지원이 안되나요?',
          content:
            '불가능합니다. \n 다만, 차량가액 3,557만원 이하의 장애인 사용(본인사용), 생업용(출퇴근 차량 불가), 유자녀용 (임산부나 만 6세 미만의 영유아와 동반한 경우에만 가능합니다.',
        },

        {
          title:
            '리스/렌트/부모님명의 차량 운행중인데 이것도 지원불가능한가요?',
          content: '불가능합니다.',
        },
        {
          title: '생업용 자동차는 어떤 것인가요?',
          content:
            '자동차를 이용하여 직접적으로 소득활동에 참여하는 차량입니다. \n 예를들어  화물, 물품 등 운반을 통해 소득활동에 참여하는 경우, 전기공이나 인테리어 기술자 등의 직업으로 도구를 차에 싣고 현장을 다니며 소득 활동을 하는 경우입니다.',
        },
        {
          title: '소득기준은 어떻게 되나요?',
          content:
            '2022년도 도시근로자 가구원수별 가구당 월평균 소득기준을 따릅니다.',
          img: require('@/assets/img/소득기준.jpg'),
        },
        {
          title: '제가 소득이 없으면 신청시 소득작성을 어떻게 해야하나요?',
          content:
            '납세사실확인증명서(소득없음 증빙) 후 “부모”의 월평균 소득합계가 소득기준이 됩니다.',
        },
        {
          title: '세대원 소득이 형제 자매도 포함인가요?',
          content:
            '아닙니다. \n 세대원 또는 세대원이 있는 세대주인 경우 형제자매는 포함되지 않으며 “부모” 및 신청자 “본인”의 소득입니다.',
        },

        {
          title: '자산은 기준이 어떻게 되나요? 주식도 포함이되나요? ',
          content:
            '네 포함됩니다. \n 금융결제원의 계좌정보통합관리 서비스에서 내역서를 참고하시면 됩니다. \n 추후 원본은 제출하게 되어있습니다.',
        },
        {
          title: '부모님/형제자매가 집이 있어도(유주택자) 신청되나요?',
          content: '네 가능합니다. \n 신청자 본인만 무주택자이면 됩니다.',
        },
        {
          title: '배우자가 집이 있어도(유주택자) 신청되나요?',
          content:
            '불가능합니다. \n 신혼부부의 경우 신청자의 배우자도 무주택이어야 합니다. ',
        },
        {
          title: '최초 모집시 실제 주택을 보고 계약할 수 있나요?',
          content:
            '최초 모집시에는 현장여건상 실제주택을 보고 계약할 수 없습니다.  \n  홈페이지 VR, 세대평면, ISO를 참조하여 계약 후 하자점검기간 2022. 4. 22. ~ 4. 24. 까지 본인 계약 주택을 보실 수 있고, 맘에 들지 않을 경우 \n 계약에 따라 계약해지하시면 됩니다.',
        },
      ],
      DataLists: [
        {
          no: '9',
          title: '구산주택 세대별 세부정보 및 안목치수',
          date: '2022-03-10',
          file: '',
        },
      ],

      currentPage: 1,
      perPage: 5,
    };
  },
  mounted() {
    this.changeView(this.contract_type);
  },
  methods: {
    openAnswer(index) {
      const answer = document.getElementById('ans-' + index);
      if (answer.style.display == 'block') {
        answer.style.display = 'none';
      } else {
        answer.style.display = 'block';
      }
    },
    ShowNotice(title, content) {
      this.noticeTitle = title;
      this.noticeContent = content;
      this.isModalNotice = true;
    },
    changeView(type) {
      if (type == '공지사항') {
        document.getElementById('tab-1').checked = true;
      } else if (type == '자료실') {
        document.getElementById('tab-2').checked = true;
      } else if (type == 'FAQ') {
        document.getElementById('tab-3').checked = true;
      } else if (type == '관심고객등록') {
        document.getElementById('tab-4').checked = true;
      }
    },
    favoriteBtn(list) {
      // 유효성검사
      var name = /^[가-힣]{2,4}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/; //이름
      var birth =
        /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/; // 생년월일
      var phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; //핸드폰 번호
      var email =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i; // 이메일

      console.log(list);

      if (list.user_name.length == 0) {
        alert('이름을 입력해주세요');

        return false;
      } else if (!name.test(list.user_name)) {
        alert('이름을 올바로 입력해주세요');

        return false;
      }
      if (list.user_birth.length == 0) {
        alert('생년월일 입력해주세요');

        return false;
      } else if (!birth.test(list.user_birth)) {
        alert('생년월일 확인해주세요');
      }
      if (list.user_number.length == 0) {
        alert('핸드폰 번호 입력해주세요');

        return false;
      } else if (!phone.test(list.user_number)) {
        alert('핸드폰 번호를 확인해 주세요');

        return false;
      }
      if (list.user_email.length == 0) {
        alert('이메일 입력해주세요');

        return false;
      } else if (!email.test(list.user_email)) {
        alert('이메일 형식이 올바르지 않습니다');

        return false;
      }
      console.log('등록');

      axios
        .post('https://duckyoung2016.com:8080/favorite', JSON.stringify(list), {
          headers: { 'Content-Type': `application/json` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.statusCode == 200) {
            alert('관심등록 되었습니다');
          } else {
            alert('다시 시도해주세요');
            console.log(response);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeType(type) {
      this.$parent.contract_type = type;
    },
  },
};
</script>
<style lang="scss">
.Service {
  position: relative;
  width: 100%;
  .inner {
    .sub_visual.sub1bg {
      .in {
        width: 100%;
        text-align: center;
        padding-top: 70px;
      }
    }
  }
  .sub_content {
    .main_title {
      position: relative;
      text-align: center;
      h2 {
        margin-bottom: 60px;
        color: black;
        font-size: 38px;
        letter-spacing: -1px;
        font-weight: 500;
      }
      h2:after {
        content: '';
        display: block;
        margin: 15px auto 0;
        width: 35px;
        height: 3px;
        background: black;
      }
    }
    .main input[type='radio'] {
      display: none;
    }
    #tab-1:checked ~ .tab label:nth-child(1),
    #tab-2:checked ~ .tab label:nth-child(2),
    #tab-3:checked ~ .tab label:nth-child(3),
    #tab-4:checked ~ .tab label:nth-child(4) {
      box-shadow: inset 0 -3px 0 #153e06;
      color: #153e06;
      font: normal normal bold 22px/33px Noto Sans KR;
      opacity: 1;
    }
    .content > div {
      display: none;
    }

    #tab-1:checked ~ .content div:nth-child(1),
    #tab-2:checked ~ .content div:nth-child(2),
    #tab-3:checked ~ .content div:nth-child(3),
    #tab-4:checked ~ .content div:nth-child(4) {
      display: block;
    }
    .main {
      margin: 0 auto;
      max-width: 1320px;
    }
    .tab {
      overflow: hidden;
      border-bottom: 1px solid #e0e0e0;
      opacity: 1;
      text-align: center;
      width: 1902px;
      margin-left: -292px;
    }
    .tab label {
      width: 206px;
      font: normal normal normal 22px/33px Noto Sans KR;
      letter-spacing: 0px;
      color: #626262;
      opacity: 1;
      cursor: pointer;
      text-align: center;
      text-align: center;
      padding: 15px 0;
      text-transform: uppercase;
      user-select: none;
      -webkit-user-select: none;
    }

    .content {
      min-height: 1000px;
    }
    .content > div {
      line-height: 1.5;
      font-size: 17px;
    }

    .titlebox {
      .title {
        margin-top: 5%;
        margin-bottom: 3%;
        h2 {
          text-align: left;
          font: normal normal 30px/36px S-Core Dream;
          letter-spacing: 0px;
          color: #333333;
          opacity: 1;
        }
      }
      .text {
        text-align: left;
        font: normal normal bold 16px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #002a9b;
        opacity: 1;
      }
    }
    .boardList {
      width: 100%;
      table {
        width: 100%;
      }
      thead {
        width: 100%;
        th {
          text-align: center;
          border-top: 1px solid #d5d5d5;
          border-bottom: 1px solid #d5d5d5;
          background: #f5f5f5 0% 0% no-repeat padding-box;
          height: 40px;
        }
        .no {
          width: 10%;
          font: normal normal 16px/24px Noto Sans KR;
          letter-spacing: 1.92px;
          color: #333333;
          opacity: 1;
        }
        .ti {
          width: 70%;
          font: normal normal 16px/24px Noto Sans KR;
          letter-spacing: 1.92px;
          color: #333333;
          opacity: 1;
          margin-right: 10%;
        }
        .da {
          width: 15%;
          font: normal normal 16px/24px Noto Sans KR;
          letter-spacing: 1.92px;
          color: #333333;
          opacity: 1;
        }
      }
      tbody {
        a {
          color: black;
          text-decoration: none;
        }
        td {
          cursor: pointer;
          text-align: center;
          height: 50px;
          font: normal normal 500 18px/27px Noto Sans KR;
          letter-spacing: 0px;
          color: #333333;
          opacity: 1;
        }
        .no {
          font: normal normal normal 14px/20px Noto Sans KR;
          color: #626262;
          letter-spacing: 0px;
          opacity: 1;
        }
        .last {
          border-bottom: 1px solid #d5d5d5;
        }
      }
    }
    // FAQ
    .F_list {
      border-top: 1px solid #d5d5d5;
      border-bottom: 1px solid #d5d5d5;
      .faq-content {
        .question {
          height: 50px;
          font: normal normal 500 20px/29px Noto Sans KR;
          letter-spacing: 1px;
          color: #333333;
          cursor: pointer;
          border: none;
          outline: none;
          background: none;
          width: 100%;
          text-align: left;
          opacity: 1;
        }
        // .question:hover { color: #2962ff;}
        span {
          padding-left: 37px;
        }
        .down {
          width: 14px;
          height: 14px;
          float: right;
          margin-right: 40px;
          margin-top: 8px;
        }
        .faq_tit {
          font: normal normal 500 20px/29px Noto Sans KR;
          letter-spacing: 1px;
          color: #333333;
        }
        // [id$="-toggle"] {margin-right: 15px;}
        .answer {
          display: none;
          padding: 30px 0;
          background: #f5f5f5 0% 0% no-repeat padding-box;
          height: auto;
          width: 1320px;
          .answers {
            width: 1147px;
            padding-left: 36px;
            font: normal normal 20px/30px Noto Sans KR;
            text-align: left;
            letter-spacing: -1px;
            color: #153e06;
            opacity: 1;
          }
        }
      }
    }
    .page-item.active .page-link {
      color: black;
      background-color: white;
      border-color: black;
    }
    .page-item.active .page-link:hover {
      color: black;
      background-color: white;
      border-color: black;
    }
    .page-link {
      color: black;
      border: 0px;
    }
    .page-link:focus {
      box-shadow: none;
    }

    // 관심고객등록
    .interest {
      table {
        width: 100%;
        margin-bottom: 10px;
        border-collapse: collapse;
        border-spacing: 0;
        margin-bottom: 50px;
        .content_title {
          font: normal normal bold 20px/29px Noto Sans KR;
          text-align: center;
          letter-spacing: 1.92px;
          color: #333333;
          opacity: 1;
          background: #f5f5f5 0% 0% no-repeat padding-box;
          opacity: 1;
          width: 208px;
          padding-left: 10px;
        }
        tr {
          border: 1px solid #e0e0e0;
        }
        td {
          padding: 10px;
          overflow: hidden;
          word-break: normal;
          padding-left: 40px;
        }
      }
      button {
        margin-top: 12px;
        background: #153e06 0% 0% no-repeat padding-box;
        border-radius: 12px;
        opacity: 1;
        height: 56px;
        width: 225px;
        border: 0;
        display: block;
        margin: 0 auto;
        p {
          text-align: center;
          font: normal normal bold 24px/36px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
